* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}

h1,
h2,
h3 {
  font-family: 'Josefin Sans', sans-serif;
}

h1,
h2 {
  color: rgba(255, 255, 255, 0.75);
}

h1 {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 60px;
}

h3 {
  letter-spacing: 1px;
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}

.hero-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero {
  height: calc(100% - 40px);
  margin: 20px;
  background: url('./3.jpg');
  background-size: cover;
  text-align: center;
  flex: 1;
}

.hero p {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.75);
  font-family: 'Josefin Sans', sans-serif;
}

.hero div {
  position: relative;
  top: 35%;
  transform: translateY(-50%);
}

.about-container {
  width: 100%;
  background-color: #607d8b;
  padding: 80px;
}

.about-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 60px;
}

.about-heading-container h3 {
  flex: 1;
  margin-right: 8px;
}

.about-heading-container a {
  display: flex;
  align-items: center;
}

.about-heading-container svg {
  width: 32px;
  height: 32px;
}

.experience {
  margin: 20px 0 40px;
  border: 2px solid #c2d4d8;
  padding: 25px 20px 20px;
  position: relative;
  color: #ffffff;
  margin-top: 60px;
}

.experience.projects {
  border-color: #7691a0;
  background-color: #c1d1da;
}

.experience:first-child {
  margin-top: 0;
}

.experience .label {
  position: absolute;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  background-color: #607d8b;
  top: -21px;
  left: 20px;
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.experience.projects .label {
  background-color: #5ca183;
  border-radius: 16px;
  font-size: 16px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.experience.projects .description {
  margin-top: 16px;
  color: #191919;
}

.experience .title {
  font-size: 16px;
  letter-spacing: 0.75px;
  margin-bottom: 2px;
}

.experience .subtitle {
  font-size: 14px;
  letter-spacing: 0.75px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 12px;
}

.experience .description {
  letter-spacing: 0.5px;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.9);
}

.experience ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.experience li {
  display: inline-block;
  margin: 5px;
  padding: 5px 15px;
  border-radius: 2em;
  background: #455a64;
  letter-spacing: 1px;
}

.experience li a {
  text-decoration: none;
  color: inherit;
}

.experience li:first-child {
  margin-left: 0;
}

@media (max-width: 960px) {
  .about-container {
    padding: 40px;
  }
}

@media (max-width: 876px) {
  .about-container {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 48px;
  }

  .about-heading-container {
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 40px;
  }

  .hero {
    margin: 14px;
    height: calc(100% - 28px);
  }

  .hero p {
    font-size: 24px;
  }

  .about-container {
    padding: 14px;
  }
}
